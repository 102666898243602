import React from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Head,
  Hero,
  Breadcrumb,
  Lazyload,
  Button,
  Form,
  Link,
  Footer,
} from '~/components'
import { scroller } from 'react-scroll'
import nl2br from 'react-nl2br'
import cn from 'classnames'
import * as st from '~/assets/styl/Sales.module.styl'
import * as st1 from '~/assets/styl/EnterpriseSlider.module.styl'
import * as st2 from '~/assets/styl/Hero.module.styl'
import Banner from '~/assets/img/Atendimento.jpg'

interface Context {
  enterprises: Array<{ name: string; id: string }>
  subjects: Array<{ name: string; id: string }>
  shifts: Array<{
    id: number
    image: string
    name: string
    whatsapp: string
    address: string
    neighborhood: string
    city: string
    state: string
    zipCode: string
    description: string
  }>
}

const Success = ({ location, pageContext }: PageProps) => {
  const {
    configuration: config,
  }: {
    configuration: {
      whatsapp: string
      phone: string
    }
  } = useApi(
    {
      configuration: useStaticQuery(graphql`
        query {
          allConfigurations {
            nodes {
              whatsapp
              phone
            }
          }
        }
      `).allConfigurations.nodes[0],
    },
    'configuration'
  )

  const { enterprises, subjects, shifts }: Context = useApi(
    pageContext,
    'page-sale'
  )

  return (
    <>
      <Head
        location={location}
        title={'Vendas - ' + process.env.GATSBY_SITE_NAME}
      />

      <Hero title="Vendas" image={Banner} />

      <Breadcrumb />

      <section className={cn(st1.core, st.attendance)} id="Atendimento">
        <div className={st1.container}>
          <h2>Canais de atendimento</h2>
          <div className={st.boxWrapper}>
            <div className={st.box}>
              <div className={st.phones}>
                {config.whatsapp && (
                  <Link
                    href={`https://api.whatsapp.com/send?phone=55${config.whatsapp.replace(
                      /\D/g,
                      ''
                    )}`}
                    external
                  >
                    <span className="icon-whatsapp"></span>
                    {config.whatsapp}
                  </Link>
                )}
                {config.phone && (
                  <Link
                    href={`tel:${config.phone.replace(/\D/g, '')}`}
                    external
                  >
                    <span className="icon-phone"></span>
                    {config.phone}
                  </Link>
                )}
              </div>
              <div className={st.form}>
                <h3>Solicite um atendimento</h3>
                <Form
                  path="page-sale"
                  idPrefix="contact"
                  button={<Button>Enviar mensagem</Button>}
                  loadingButton={<Button>Enviando...</Button>}
                  inputs={[
                    {
                      name: 'name',
                      label: 'Nome*',
                    },
                    {
                      name: 'email',
                      label: 'E-mail*',
                      type: 'email',
                    },
                    {
                      name: 'phone',
                      label: 'WhatsApp*',
                      mask: 'phone',
                      className: 'small',
                    },
                    {
                      name: 'enterprise',
                      label: 'Empreendimento de interesse*',
                      type: 'select',
                      options: enterprises.map(({ name }) => ({
                        name,
                      })),
                      placeholder: 'Selecione',
                      className: 'small',
                    },
                    {
                      name: 'subject',
                      label: 'O que você está buscando?*',
                      type: 'select',
                      options: subjects.map(({ name }) => ({
                        name,
                      })),
                      placeholder: 'Selecione',
                    },
                    {
                      name: 'privacy',
                      label: (
                        <>
                          Declaro que li e aceito a{' '}
                          <Link href="/politica-de-privacidade/">
                            Política de Privacidade
                          </Link>
                        </>
                      ),
                      type: 'checkbox',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(st1.core, st.shifts)}>
        <div className={st1.container}>
          <h2>Plantões de venda</h2>
          {shifts.map(
            ({
              id,
              image,
              neighborhood,
              city,
              state,
              address,
              zipCode,
              description,
            }) => (
              <div className={st.box} key={id}>
                <Lazyload src={image} className={st.image} />
                <div className={st.text}>
                  <span className={st2.pretitle}>
                    {[neighborhood, city, state].filter((i) => i).join(' - ')}
                  </span>
                  <h3>Águas de Alonso Residence</h3>
                  <p>
                    {[
                      [address, neighborhood].filter((i) => i).join(' - '),
                      [city, state].filter((i) => i).join(' - '),
                    ]
                      .filter((i) => i)
                      .join(', ')}
                    {zipCode && (
                      <>
                        <br />
                        {zipCode}
                      </>
                    )}
                  </p>
                  <div className={st.box}>
                    {description && <p>{nl2br(description)}</p>}
                    <Button
                      onClick={() =>
                        scroller.scrollTo('Atendimento', {
                          smooth: true,
                          duration: 500,
                        })
                      }
                    >
                      Solicite informações
                    </Button>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Success
